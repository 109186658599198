<template>
  <v-container
    class="pt-0 pb-0"
    :class="{ 'pr-0 pl-0': !$vuetify.breakpoint.mdAndUp }"
  >
    <v-row>
      <v-col cols="12">
        <v-card
          color="blue-grey darken-2 d-flex align-center justify-space-between"
          dark
        >
          <v-container class="pl-4">
            <p class="text-subtitle-2 mb-1 font-weight-light">
              Factura mensual actual
            </p>
            <p class="text-h6 ma-0">
              $ <span v-if="!loader">{{ plan.price }}.00</span>
              <v-progress-circular
                indeterminate
                size="20"
                v-else
              ></v-progress-circular>
              <span class="caption ml-1">MXN</span>
            </p>
          </v-container>
          <div class="pr-4">
            <v-icon size="30">credit_card</v-icon>
          </div>
        </v-card>
      </v-col>
      <v-col class="12">
        <h3 class="text-h6 mb-2">Historial de facturas</h3>
        <div class="text-center" v-if="loader">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <v-btn
          v-else
          block
          color="#4d90fe"
          class="white--text"
          @click="getPortalLinkCustomer"
          >Ver facturación</v-btn
        >
      </v-col>
      <v-col cols="12">
        <h3 class="text-h6 mb-2">Plan actual</h3>
        <div class="text-center" v-if="loader">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <v-expand-transition>
          <v-card
            v-if="!loader"
            outlined
            class="text-center d-flex flex-column justify-space-between"
            :max-width="$vuetify.breakpoint.width < 673 ? '100%' : '320'"
          >
            <v-container class="d-flex align-center justify-space-between">
              <div class="d-flex align-center">
                <img
                  v-if="$vuetify.theme.dark"
                  src="../../assets/ssencial-logo-white.svg"
                  alt="ssencial"
                  height="18px"
                />
                <img
                  v-else
                  src="../../assets/ssencial-logo.svg"
                  alt="ssencial"
                  height="18px"
                />
                <!-- <v-img src="" width="30" height="30" contain></v-img> -->
                <p class="text-h5 ma-0 ml-1">{{ plan.type }}</p>
              </div>
              <v-btn outlined small href="mailto:edwin@ssencial.com"
                >update</v-btn
              >
            </v-container>
            <v-divider></v-divider>
            <v-container class="px-5">
              <p class="ma-0 font-weight-light">{{ plan.description }}</p>
            </v-container>
            <v-container class="px-5">
              <template v-for="(feature, index) in plan.features">
                <p class="ma-0 font-weight-medium" :key="index + '-title'">
                  {{ feature.title }}
                </p>
                <p
                  :key="index + '.-desc'"
                  class="caption mb-0"
                  :class="{ 'mb-3': plan.features.length > index + 1 }"
                >
                  {{ feature.decription }}
                </p>
              </template>
            </v-container>
          </v-card>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      plan: null,
      loader: true
    };
  },
  beforeMount() {
    this.getCurrentPlanData();
  },
  methods: {
    ...mapActions("business", [
      "getBusinessPlan",
      "getPlanData",
      "getPortalLinkCustomer"
    ]),
    async getCurrentPlanData() {
      this.loader = true;
      if (this.getBusinessPlanID) {
        this.plan = await this.getPlanData(this.getBusinessPlanID);
        this.loader = false;
      } else {
        const planData = await this.getBusinessPlan();
        this.plan = await this.getPlanData(planData.id);
        this.loader = false;
      }
    }
  },
  computed: {
    ...mapGetters("business", ["getBusinessPlanID"])
  }
};
</script>

<style></style>
